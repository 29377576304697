import {Link, Container, Typography, Toolbar} from '@mui/material';
import React from 'react';
import {Outlet, Link as RouterLink} from 'react-router-dom';
import RRTStar from './routes/rrt_star';

function App() {
  return (
    <Container maxWidth="xl">
      <Toolbar sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{flex: 1}}
        >s-mc</Typography>
      </Toolbar>
      <main>
        <Typography>
          Below is an interactive virtual reality visualization of the Rapidly-exploring Random Tree Star (RRT*) algorithm.
          The green sphere is the start and the red sphere is the goal.
          Many more details on rrt* can be found in the paper <Link href="http://arxiv.org/pdf/1105.1186">Sampling-based algorithms for optimal motion planning</Link> by Sertac Karaman and Emilio Frazzoli.
        </Typography>
        <RRTStar />
      </main>
    </Container >
  );
}

export default App;
