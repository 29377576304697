import React, {useRef, useState} from 'react';

function CalculatorReducer(state: any, action: string) {
  // const [registerA, setRegisterA] = useState('0');
  // const [operator, setOperator] = useState('');
  // const [registerB, setRegisterB] = useState('0');

  // const handleDigit = (number: number) => {
  //   return () => {
  //     // TODO: Add digit to first register if there is no operator.
  //     // TODO: Limit the number of digits that can be used. How does . impact
  //     return number;
  //   }
  // }
  // const handleClear = () => {
  //   // TODO: understand the edge cases between C and AC
  // }
  // const handleNegate = () => {
  //   // TODO: ensure -0 is allowed
  //   // TODO: this operates on the current register
  // }
  // const handlePercent = () => {
  //   // TODO: this operates on the current register
  // }

  // // TODO: Operators must apply previous stored operator if both registers have
  // // data.
  // const handleDivide = () => {
  //   setOperator('/');
  // }
  // const handleMultiply = () => {
  //   setOperator('*');
  // }
  // const handleSubtract = () => {
  //   setOperator('-');
  // }
  // const handleAdd = () => {
  //   setOperator('+');
  // }

  // const handleEqual = () => {
  //   // TODO: apply the operator to the two registers
  //   // TODO: if register two is empty then use the first register for both 
  // }
  // const handleDecimal = () => {
  //   // TODO: ensure we don't try to add more than one decimal
  // }
  switch (action) {
    case '1':
      console.log('1');
      break;
    default:
      throw new Error('invalid action');
  }

  console.log({state, action});
  return state;
}

export default CalculatorReducer;
