import React, {useEffect, useMemo, useState, useRef, useLayoutEffect, useReducer} from 'react';
import {goalReached, cloneGraph, Configuration, rrtStar, rrtStarExtend, generateObstacle, randomConfiguration, collisonFree, Obstacle, FlatGraph, deserializeGraph, serializeGraph, linksBackToRootNode} from './rrt_star';
import Worker from '../../worker';
import {Vector3, BufferGeometry, Material, Object3D, Line3, DoubleSide, Color} from 'three';
import GraphDisplay from './graph_display';
// TODO: remove the need for this to show the distance
import {Text} from "@react-three/drei";

export interface GraphFieldProps {
  enable: boolean;
  obstacles: Array<Obstacle>;
  incDistance: number;
  // Distance each step takes during the simulation.
  nodeCountStep: number;
  maxNodes: number;
  timeStep: number;
}

const nodeCountIncrement = 5000;

const GraphField = (props: GraphFieldProps) => {
  // const workerInstance = useMemo<Worker>(() => new Worker(), []);
  // const workerBusy = useRef<boolean>(false);
  // const [graphInitStarted, setGraphInitStarted] = useState<boolean>(false);
  // const [graph, setGraph] = useState<any>();
  // const graph = useRef<any>();
  const [initialConfig] = useState(() => {
    for (; ;) {
      const config = randomConfiguration();
      if (collisonFree(config, props.obstacles)) {
        // console.log({initialConfig: config});
        return config;
      }
    }
  });
  const [goalConfig] = useState(() => {
    for (; ;) {
      const config = randomConfiguration();
      if (collisonFree(config, props.obstacles)) {
        // console.log({goalConfig: config});
        return config;
      }
    }
  });

  // useEffect(() => {
  //   // TODO: find a better way to do this.
  //   // This second state variable is used because creating the graph may take some time.
  //   if (graphInitStarted) {
  //     return;
  //   } else {
  //     setGraphInitStarted(true);
  //   };
  //   const buildGraph = async () => {
  //     console.log('create graph');
  //     workerBusy.current = true;
  //     try {
  //       const result = await workerInstance.rrtStarWrap(initialConfig, goalConfig, props.obstacles, 1, props.incDistance);
  //       workerBusy.current = false;
  //       console.log('created graph');
  //       const tempGraph = deserializeGraph(result);
  //       graph.current = tempGraph;
  //       // renderGraph();
  //     } catch (e) {
  //       console.log(e);
  //       throw e;
  //     }
  //   };
  //   buildGraph();
  // }, [graphInitStarted, workerInstance]);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // setGraph((previousGraph: any) => {
  //     if (graph.current == null) {
  //       return;
  //     } else if (!props.enable) {
  //       return;
  //     } else if (workerBusy.current) {
  //       console.log('skipping graph processing step');
  //       return;
  //     }
  //     const graphStep = async () => {
  //       workerBusy.current = true;
  //       const result = await workerInstance.rrtStarExtendWrap(serializeGraph(graph.current), goalConfig, props.obstacles, props.nodeCountStep, props.incDistance);
  //       workerBusy.current = false;
  //       const tempGraph = deserializeGraph(result);
  //       graph.current = tempGraph;
  //     };

  //     graphStep();
  //     // })
  //   }, props.timeStep);
  //   return () => {
  //     console.log('clear graph update');
  //     clearInterval(interval)
  //   };
  // }, [props.enable, props.obstacles, workerInstance, props.incDistance]);

  return (
    <GraphDisplay
      enable={props.enable}
      obstacles={props.obstacles}
      incDistance={props.incDistance}
      initialConfig={initialConfig}
      goalConfig={goalConfig}
      nodeCountStep={props.nodeCountStep}
      maxNodes={props.maxNodes}
      timeStep={props.timeStep}
    />
  )
}

export default GraphField;
