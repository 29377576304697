import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Graph from './routes/graph';
import Calculator from './routes/calculator';
import VRWrapper from './routes/vr_wrapper';
import RRTStar from './routes/rrt_star';

// Material UI requests these be loaded at the entrypoint.
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="vr" element={<VRWrapper />} />
        <Route path="graph" element={<Graph />} />
        <Route path="calculator" element={<Calculator />} />
        <Route path="rrt_star" element={<RRTStar />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
