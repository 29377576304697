import React, {useReducer} from 'react';
import CalculatorReducer from './calculator_reducer';

const initialState = {display: 0};

function Calculator(props: any) {
  const [state, dispatch] = useReducer(CalculatorReducer, initialState)
  console.log('calc render');
  return (
    <table>
      <tbody>
        <tr>
          <td>{state.display}</td>
        </tr>
        <tr>
          <td><button onClick={() => dispatch('ac')}>AC</button></td>
          <td><button onClick={() => dispatch('+/-')}>+/-</button></td>
          <td><button onClick={() => dispatch('%')}>%</button></td>
          <td><button onClick={() => dispatch('/')}>/</button></td>
        </tr>
        <tr>
          <td><button onClick={() => dispatch('7')}>7</button></td>
          <td><button onClick={() => dispatch('8')}>8</button></td>
          <td><button onClick={() => dispatch('9')}>9</button></td>
          <td><button onClick={() => dispatch('*')}>*</button></td>
        </tr>
        <tr>
          <td><button onClick={() => dispatch('4')}>4</button></td>
          <td><button onClick={() => dispatch('5')}>5</button></td>
          <td><button onClick={() => dispatch('6')}>6</button></td>
          <td><button onClick={() => dispatch('-')}>-</button></td>
        </tr>
        <tr>
          <td><button onClick={() => dispatch('1')}>1</button></td>
          <td><button onClick={() => dispatch('2')}>2</button></td>
          <td><button onClick={() => dispatch('3')}>3</button></td>
          <td><button onClick={() => dispatch('+')}>+</button></td>
        </tr>
        <tr>
          <td><button onClick={() => dispatch('0')}>0</button></td>
          <td><button onClick={() => dispatch('.')}>.</button></td>
          <td><button onClick={() => dispatch('=')}>=</button></td>
        </tr>
      </tbody>
    </table>
  );
}

export default Calculator;
