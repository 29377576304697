import {Mesh} from 'three';
import React, {useRef, useState} from 'react';
import {RayGrab} from '@react-three/xr'
import ButtonPad from './vr_calc_button_pad';
import CalcDisplay from './vr_calc_display';

function VRCalculator(props: JSX.IntrinsicElements['mesh']) {
  const ref = useRef<Mesh>(null!)
  const [registerA, setRegisterA] = useState('0');
  const [operator, setOperator] = useState('');
  const [registerB, setRegisterB] = useState('0');

  const handleDigit = (number: number) => {
    return () => {
      // TODO: Add digit to first register if there is no operator.
      // TODO: Limit the number of digits that can be used. How does . impact
      return number;
    }
  }
  const handleClear = () => {
    // TODO: understand the edge cases between C and AC
  }
  const handleNegate = () => {
    // TODO: ensure -0 is allowed
    // TODO: this operates on the current register
  }
  const handlePercent = () => {
    // TODO: this operates on the current register
  }

  // TODO: Operators must apply previous stored operator if both registers have
  // data.
  const handleDivide = () => {
    setOperator('/');
  }
  const handleMultiply = () => {
    setOperator('*');
  }
  const handleSubtract = () => {
    setOperator('-');
  }
  const handleAdd = () => {
    setOperator('+');
  }

  const handleEqual = () => {
    // TODO: apply the operator to the two registers
    // TODO: if register two is empty then use the first register for both 
  }
  const handleDecimal = () => {
    // TODO: ensure we don't try to add more than one decimal
  }

  return (
    <RayGrab>
      <mesh
        {...props}
        ref={ref}>
        <boxGeometry args={[0.1, 1.0, 0.6]} />
        <meshStandardMaterial color={'hotpink'} />
      </mesh>
      <CalcDisplay position={props.position} scale={props.scale} />
      <ButtonPad position={props.position} scale={props.scale}
        onSelectDigit={handleDigit}
        onSelectClear={handleClear}
        onSelectNegate={handleNegate}
        onSelectPercent={handlePercent}
        onSelectDivide={handleDivide}
        onSelectMultiply={handleMultiply}
        onSelectSubtract={handleSubtract}
        onSelectAdd={handleAdd}
        onSelectEqual={handleEqual}
        onSelectDecimal={handleDecimal}
      />
    </RayGrab>
  )
}

export default VRCalculator;
