import React, {useEffect, useMemo, useState, useRef, useLayoutEffect} from 'react';
import {goalReached, cloneGraph, Configuration, rrtStar, rrtStarExtend, generateObstacle, randomConfiguration, collisonFree, Obstacle, FlatGraph, deserializeGraph, serializeGraph, linksBackToRootNode} from './rrt_star';
import {Vector3, BufferGeometry, Material, Object3D, Line3, DoubleSide, Color} from 'three';
// TODO: remove the need for this to show the distance
import {Text} from "@react-three/drei";
import * as Comlink from 'comlink';
import {RRTWorker} from '../../worker/worker';

export interface GraphDisplayProps {
  enable: boolean;
  obstacles: Array<Obstacle>;
  // Distance each step takes during the simulation.
  nodeCountStep: number;
  timeStep: number;
  incDistance: number;
  maxNodes: number;
  initialConfig: Configuration;
  goalConfig: Configuration;
}

const nodeCountIncrement = 2000;

const GraphDisplay = (props: GraphDisplayProps) => {
  // console.log('graph display top');
  const workerInstance = useMemo<Comlink.Remote<RRTWorker>>(() => {
    return Comlink.wrap(new Worker(new URL('../../worker/worker.ts', import.meta.url)))
  }, [])

  const workerBusy = useRef<boolean>(false);
  const [graphInitStarted, setGraphInitStarted] = useState<boolean>(false);
  // const [graph, setGraph] = useState<any>();
  const graph = useRef<any>();
  const nodesRef = useRef<any>();
  const arrowsRef = useRef<any>();
  const tempObject = useMemo(() => new Object3D(), []);
  const tempColor = useMemo(() => new Color(), []);
  const [nodeCount, setNodeCount] = useState<number>(2000);
  const [arrowCount, setArrowCount] = useState<number>(2000);
  const [minCostPath, setMinCostPath] = useState<number | null>(null);
  const colorArray = useMemo(() => Float32Array.from(new Array(arrowCount).fill(255).flatMap((_, i) => tempColor.set('blue').toArray())), [arrowCount]);
  const minCostPathLinks = useRef<Set<string>>(new Set());

  // TODO: figure out type of position
  // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/61f2fbe571f0968b8efbb36317e83d746b6d8b0b/types/three/src/math/Vector3.d.ts
  const configurationToThreeVector = (config: Configuration): any => {
    return [config[0], config[1], config[2]];
  }

  useEffect(() => {
    // TODO: find a better way to do this.
    // This second state variable is used because creating the graph may take some time.
    if (graphInitStarted) {
      return;
    } else {
      setGraphInitStarted(true);
    };
    const buildGraph = async () => {
      // console.log('create graph');
      workerBusy.current = true;
      try {
        const result = await workerInstance.rrtStarWrap(props.initialConfig, props.goalConfig, props.obstacles, 1, props.incDistance);
        workerBusy.current = false;
        // console.log('created graph');
        const tempGraph = deserializeGraph(result);
        graph.current = tempGraph;
        // renderGraph();
      } catch (e) {
        console.log(e);
        throw e;
      }
    };
    buildGraph();
  }, [graphInitStarted, workerInstance]);

  useEffect(() => {
    const interval = setInterval(() => {
      // setGraph((previousGraph: any) => {
      if (graph.current == null) {
        return;
      } else if (props.maxNodes < nodeCount) {
        return;
      } else if (!props.enable) {
        return;
      } else if (workerBusy.current) {
        // console.log('skipping graph processing step');
        return;
      }
      const graphStep = async () => {
        workerBusy.current = true;
        const result = await workerInstance.rrtStarExtendWrap(serializeGraph(graph.current), props.goalConfig, props.obstacles, props.nodeCountStep, props.incDistance);
        workerBusy.current = false;
        const tempGraph = deserializeGraph(result);
        graph.current = tempGraph;
        renderGraph();
      };

      graphStep();
      // })
    }, props.timeStep);
    return () => {
      // console.log('clear graph update');
      clearInterval(interval)
      renderGraph();
    };
  }, [props.enable, props.obstacles, workerInstance, props.incDistance, props.nodeCountStep, props.maxNodes, nodeCount]);

  const renderGraph = () => {
    // useLayoutEffect(() => {
    if (graph.current == null) {
      return;
    }
    if (nodesRef == null || nodesRef.current == null) {
      return;
    }
    if (arrowsRef == null || arrowsRef.current == null) {
      return;
    }

    // console.log('handle graph to mesh');

    let index = 0;
    let localMinCostPath = minCostPath;
    let localMinCostPathLinks = minCostPathLinks.current;
    graph.current?.forEachNode((node: any) => {
      const config = configurationToThreeVector(node.data.config);
      // TODO: handle the case when multipe path lead to the goal and the minCostPath state is stale
      if (goalReached(config, props.goalConfig, props.incDistance) && (localMinCostPath == null || node.data.cost < localMinCostPath)) {
        localMinCostPath = node.data.cost;
        // console.log(node.data.cost);
        localMinCostPathLinks = linksBackToRootNode(graph.current, node.id);
        // console.log(localMinCostPathLinks);
      }
      tempObject.position.set(config[0], config[1], config[2])
      tempObject.scale.set(1, 1, 1);
      tempObject.rotation.set(0, 0, 0)
      tempObject.updateMatrix();
      nodesRef.current.setMatrixAt(index, tempObject.matrix);
      index++;
    });
    if (localMinCostPath !== minCostPath) {
      setMinCostPath(localMinCostPath);
      minCostPathLinks.current = localMinCostPathLinks;
    }
    if (nodeCount - nodeCountIncrement < index) {
      setNodeCount((previousNodeCount: number) => {
        // console.log({previousNodeCount, index});
        if (previousNodeCount - nodeCountIncrement < index) {
          return previousNodeCount + nodeCountIncrement;
        } else {
          return previousNodeCount;
        }
      });
    }
    index = 0;
    graph.current?.forEachLink((link: any) => {
      // TODO: make this much faster
      if (localMinCostPathLinks.has(link.id)) {
        tempColor.set('red').toArray(colorArray, index * 3);
        arrowsRef.current.geometry.attributes.color.needsUpdate = true;
      } else {
        tempColor.set('blue').toArray(colorArray, index * 3);
        arrowsRef.current.geometry.attributes.color.needsUpdate = true;
      }
      const fromPos = configurationToThreeVector(graph.current.getNode(link.fromId).data.config);
      const toPos = configurationToThreeVector(graph.current.getNode(link.toId).data.config);
      const origin = new Vector3(fromPos[0], fromPos[1], fromPos[2]);
      const target = new Vector3(toPos[0], toPos[1], toPos[2]);
      const dir = new Vector3(toPos[0] - fromPos[0], toPos[1] - fromPos[1], toPos[2] - fromPos[2]);
      const length = dir.length();
      const pos = origin.clone().add(dir.clone().divideScalar(2));
      dir.normalize();
      tempObject.position.set(pos.x, pos.y, pos.z);
      tempObject.scale.set(0.002, length, 0.002);
      tempObject.lookAt(target)
      tempObject.rotateOnAxis(new Vector3(1, 0, 0), Math.PI / 2);
      tempObject.updateMatrix();
      arrowsRef.current.setMatrixAt(index, tempObject.matrix);
      //     <arrowHelper key={arrowId} args={[dir, origin, length, 0x0000ff, 0.05, 0.02]} />
      index++;
    });
    if (arrowCount - nodeCountIncrement < index) {
      setArrowCount((previousArrowCount: number) => {
        if (previousArrowCount - nodeCountIncrement < index) {
          return previousArrowCount + nodeCountIncrement;
        } else {
          return previousArrowCount;
        }
      });
    }
    nodesRef.current.instanceMatrix.needsUpdate = true;
    arrowsRef.current.instanceMatrix.needsUpdate = true;
    // TODO: confirm the dependeicies here
    // TODO: ensure the instances are updated when the node count grows

    // console.log('graph to mesh done');
  };
  // }, [props.graph]);

  // useEffect(() => {
  //   renderGraph();
  // }, [graph.current]);
  try {

    renderGraph();
  } catch (e) {
    // console.log({graph: graph.current});
    console.log(e);
    throw e;
  }

  const obstacleBoxes: Array<JSX.Element> = [];
  props.obstacles.forEach((obstacle) => {
    const low = configurationToThreeVector(obstacle.low);
    const high = configurationToThreeVector(obstacle.high);
    const size = [high[0] - low[0], high[1] - low[1], high[2] - low[2]];
    const line = new Line3(new Vector3(low[0], low[1], low[2]), new Vector3(high[0], high[1], high[2]));
    const pos = new Vector3();
    line.getCenter(pos);
    obstacleBoxes.push((
      <mesh key={`obstacle-${obstacle.id}`} position={pos.toArray()}>
        <boxBufferGeometry args={[size[0], size[1], size[2]]} />
        <meshStandardMaterial color={'black'} opacity={0.5} transparent={true} />
      </mesh >
    ));
  });

  // console.log('render display');
  return (
    <>
      <instancedMesh key={`nodes`} ref={nodesRef} args={[null as unknown as BufferGeometry, [] as unknown as Material, nodeCount]}>
        <sphereBufferGeometry args={[0.007]} />
        <meshStandardMaterial color={'yellow'} />
      </instancedMesh>
      <instancedMesh key={`arrows`} ref={arrowsRef} args={[null as unknown as BufferGeometry, [] as unknown as Material, arrowCount]}>
        <cylinderBufferGeometry args={[1, 1, 1]} >
          <instancedBufferAttribute attach="attributes-color" args={[colorArray, 3]} />
        </cylinderBufferGeometry>
        <meshPhongMaterial vertexColors={true} />
      </instancedMesh>
      <mesh key={'goal_config'} position={[props.goalConfig[0], props.goalConfig[1], props.goalConfig[2]]}>
        <sphereBufferGeometry args={[props.incDistance]} />
        <meshStandardMaterial color={'red'} />
      </mesh>
      <Text color='black' position={[props.goalConfig[0] + 0.6, props.goalConfig[1], props.goalConfig[2]]} fontSize={0.1}>
        {minCostPath == null ? 'n/a' : minCostPath}
      </Text>
      <mesh key={'initial_config'} position={[props.initialConfig[0], props.initialConfig[1], props.initialConfig[2]]}>
        <sphereBufferGeometry args={[props.incDistance]} />
        <meshStandardMaterial color={'green'} />
      </mesh>
      {obstacleBoxes}
    </>
  )
}

export default GraphDisplay;
