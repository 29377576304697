import React, {useState, useEffect} from 'react';
import {Canvas} from '@react-three/fiber';
import {VRCanvas, DefaultXRControllers} from '@react-three/xr';
import {Link} from 'react-router-dom';
import {OrbitControls, Environment, Sky} from '@react-three/drei';
import GraphField from '../components/rrt_star/graph_field';
import CoordinateArrows from '../components/rrt_star/coordinate_arrows';
import {Obstacle, generateObstacle} from '../components/rrt_star/rrt_star';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import {FormControlLabel, Switch, Typography} from '@mui/material';

const defaultIncDistance = 0.1;
const defaultNodeCountStep = 60;
const defaultTimeStep = 1000;
const defaultMaxNodeCount = 5000;

export default function RRTStar() {
  const [computeEnable, setComputeEnable] = useState<boolean>(true);
  const [maxNodeCount, setMaxNodeCount] = useState<number>(defaultMaxNodeCount);
  const [incDistance, setIncDistance] = useState<number>(defaultIncDistance);
  const [nodeCountStep, setNodeCountStep] = useState<number>(defaultNodeCountStep);
  const [timeStep, setTimeStep] = useState<number>(defaultTimeStep);

  // TODO: there must be a better way to do this.
  useEffect(() => {
    const interval = setInterval(() => {
      const aTags = document.getElementsByTagName('a');
      if (aTags.length == 2) {
        document.getElementById('rrt_star_canvas')?.appendChild(aTags[1]);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [])

  const handleComputeEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComputeEnable((previous) => !previous);
  }
  const handleStepDistanceChange = (event: Event, value: number | number[]) => {
    setIncDistance(Number(value))
  }
  const handleNodeCountStepChange = (event: Event, value: number | number[]) => {
    setNodeCountStep(Number(value));
  }
  const handleMaxNodeCountChange = (event: Event, value: number | number[]) => {
    setMaxNodeCount(Number(value));
  }
  const [obstacles, setObstacles] = useState<Array<Obstacle>>(() => {
    return [
      generateObstacle('1'),
      generateObstacle('2'),
      generateObstacle('3'),
      generateObstacle('4'),
      generateObstacle('5'),
      generateObstacle('6'),
    ];
  });

  const handleObstacleGeneration = () => {
    setObstacles([
      generateObstacle('1'),
      generateObstacle('2'),
      generateObstacle('3'),
      generateObstacle('4'),
      generateObstacle('5'),
      generateObstacle('6'),
    ]);
  }

  return (
    <>
      <VRCanvas id="rrt_star_canvas" style={{minHeight: '300px', height: '60vh', marginTop: '15px', marginBottom: '15px'}}>
        <Sky />
        <OrbitControls autoRotate={true} />
        <DefaultXRControllers />
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <GraphField
          enable={computeEnable}
          obstacles={obstacles}
          incDistance={incDistance}
          nodeCountStep={nodeCountStep}
          maxNodes={maxNodeCount}
          timeStep={timeStep}
        />
      </VRCanvas>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControlLabel
            control={<Switch
              checked={computeEnable}
              onChange={handleComputeEnableChange}
              inputProps={{"aria-label": "Toggle Simulation"}}
            />}
            label="Toggle Simulation"
          />
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" onClick={() => handleObstacleGeneration()}>Regenerate Obstacles</Button>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>Step Distance</Typography>
          <Slider
            area-label="Step Distance"
            value={incDistance}
            step={0.0001}
            min={0}
            max={1}
            onChange={handleStepDistanceChange}></Slider>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>Node Count Step</Typography>
          <Slider
            area-label="Node Count Step"
            value={nodeCountStep}
            step={1}
            min={0}
            max={1000}
            onChange={handleNodeCountStepChange}></Slider>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>Max Node Count</Typography>
          <Slider
            area-label="Max Node Count"
            value={maxNodeCount}
            step={1}
            min={0}
            max={10000}
            onChange={handleMaxNodeCountChange}></Slider>
        </Grid>
      </Grid>
    </>
  );
}
