import {Mesh, Vector3} from 'three';
import React, {useRef} from 'react';

const CalcDisplay = (props: any) => {
  const ref = useRef<Mesh>(null!)
  let position = new Vector3(0.03, 0.3725, 0);
  if (props.position === undefined) {
  } else if (typeof props.position === "number") {
  } else if ("add" in props.position) {
    position = position.add(props.position);
  } else {
    position = position.add(new Vector3(...props.position));
  }

  return (
    <mesh
      position={position}
      ref={ref}
      scale={props.scale}>
      <boxGeometry args={[0.05, 0.15, 0.5]} />
      <meshStandardMaterial color={'black'} />
    </mesh>
  )
}

export default CalcDisplay;
