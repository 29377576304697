import React from 'react';
import Node from './node';

function AbstractGraphGame() {
  return (
    <>
      <Node position={[0.5, 1, 0]} />
      <Node position={[0, 1, 0]} />
      <Node position={[-0.5, 1, 0]} />
      <Node position={[-1, 1, 0]} />
    </>
  )
}

export default AbstractGraphGame;
