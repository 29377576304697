import {useState} from 'react';
import {VRCanvas, DefaultXRControllers, useXREvent} from '@react-three/xr';
import {Link} from 'react-router-dom';
import VRMenu from '../components/vr_menu';

export default function VRWrapper() {
  return (
    <>
      <Link to="/">Home</Link>
      <VRCanvas>
        <DefaultXRControllers />
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <VRMenu />
      </VRCanvas>
    </>
  );
}
