import {VRCanvas, DefaultXRControllers} from '@react-three/xr';
import {Link} from 'react-router-dom';
import {useState} from 'react';
import VRCalculator from '../components/calculator/vr_calculator';
import Calculator from '../components/calculator/calculator';

export default function CalculatorRoute() {
  const [VRToggle, setVRToggle] = useState(false);
  const vrCalc = (
    <VRCanvas>
      <DefaultXRControllers />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <VRCalculator position={[0, 1, 0]} scale={1} />
    </VRCanvas>
  );
  const flatCalc = (
    <Calculator />
  );

  return (
    <>
      <h1 onClick={() => setVRToggle((p) => !p)}>toggle</h1>
      <Link to="/">Home</Link>
      <Link to="/graph">Graph Clicker</Link>
      {VRToggle ? vrCalc : flatCalc}
    </>
  );
}
