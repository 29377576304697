import {Mesh} from 'three';
import React, {useRef} from 'react';
import {Interactive} from '@react-three/xr'

const CalcButton = (props: any) => {
  const ref = useRef<Mesh>(null!)
  return (
    <Interactive onSelect={() => props.handleSelect(props.button)}>
      <mesh
        position={props.position}
        ref={ref}
        scale={props.scale}>
        <boxGeometry args={[0.05, 0.1, 0.1]} />
        <meshStandardMaterial color={props.color} />
      </mesh>
    </Interactive>
  )
}

export default CalcButton;
