import {useXREvent} from "@react-three/xr";
import {useState} from "react";
import AbstractGraphGame from "./abstract_graph_game";

export default function VRMenu() {
  const [show, setShow] = useState(false);
  useXREvent('squeeze', () => setShow((s) => !s));
  const context = show ? <AbstractGraphGame /> : <></>;
  return (
    context
  );
}
