import {Mesh, Vector3} from 'three';
import React, {useRef} from 'react';
import CalcButton from './vr_calc_button';

const ButtonPad = (props: any) => {
  let position = new Vector3(0, 0, 0);

  if (props.position === undefined) {
  } else if (typeof props.position === "number") {
  } else if ("add" in props.position) {
    position = position.add(props.position);
  } else {
    position = position.add(new Vector3(...props.position));
  }
  return (
    <>
// TODO: AC changes to C in some cases. Such as after a number is entered
      <CalcButton button={'AC'} onSelect={props.onSelectClear} position={position.clone().add(new Vector3(0.05, 0.20, 0.2))} scale={props.scale} color={'white'} />
      <CalcButton button={'+/-'} onSelect={props.onSelectNegate} position={position.clone().add(new Vector3(0.05, 0.20, 0.0666))} scale={props.scale} color={'white'} />
      <CalcButton button={'%'} onSelect={props.onSelectPercent} position={position.clone().add(new Vector3(0.05, 0.20, -0.0666))} scale={props.scale} color={'white'} />
      <CalcButton button={'/'} onSelect={props.onSelectDivide} position={position.clone().add(new Vector3(0.05, 0.20, -0.2))} scale={props.scale} color={'orange'} />


      <CalcButton button={'7'} onSelect={props.onSelectDigit(7)} position={position.clone().add(new Vector3(0.05, 0.05, 0.2))} scale={props.scale} color={'gray'} />
      <CalcButton button={'8'} onSelect={props.onSelectDigit(8)} position={position.clone().add(new Vector3(0.05, 0.05, 0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'9'} onSelect={props.onSelectDigit(9)} position={position.clone().add(new Vector3(0.05, 0.05, -0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'*'} onSelect={props.onSelectMultiply} position={position.clone().add(new Vector3(0.05, 0.05, -0.2))} scale={props.scale} color={'orange'} />


      <CalcButton button={'4'} onSelect={props.onSelectDigit(4)} position={position.clone().add(new Vector3(0.05, -0.10, 0.2))} scale={props.scale} color={'gray'} />
      <CalcButton button={'5'} onSelect={props.onSelectDigit(5)} position={position.clone().add(new Vector3(0.05, -0.10, 0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'6'} onSelect={props.onSelectDigit(6)} position={position.clone().add(new Vector3(0.05, -0.10, -0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'-'} onSelect={props.onSelectSubtract} position={position.clone().add(new Vector3(0.05, -0.10, -0.2))} scale={props.scale} color={'orange'} />


      <CalcButton button={'1'} onSelect={props.onSelectDigit(1)} position={position.clone().add(new Vector3(0.05, -0.25, 0.2))} scale={props.scale} color={'gray'} />
      <CalcButton button={'2'} onSelect={props.onSelectDigit(2)} position={position.clone().add(new Vector3(0.05, -0.25, 0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'3'} onSelect={props.onSelectDigit(3)} position={position.clone().add(new Vector3(0.05, -0.25, -0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'+'} onSelect={props.onSelectAdd} position={position.clone().add(new Vector3(0.05, -0.25, -0.2))} scale={props.scale} color={'orange'} />


// TODO: handle double wide 0 bon
      <CalcButton button={'0'} onSelect={props.onSelectDigit(0)} position={position.clone().add(new Vector3(0.05, -0.40, 0.2))} scale={props.scale} color={'gray'} />
      <CalcButton button={'.'} onSelect={props.onSelectDecimal} position={position.clone().add(new Vector3(0.05, -0.40, -0.0666))} scale={props.scale} color={'gray'} />
      <CalcButton button={'='} onSelect={props.onSelectEqual} position={position.clone().add(new Vector3(0.05, -0.40, -0.2))} scale={props.scale} color={'orange'} />
    </>
  )
}

export default ButtonPad;
