import React, {useRef, useState} from 'react';
import {Interactive} from '@react-three/xr';
import {Text} from '@react-three/drei';

function Node(props: {position: [number, number, number]}) {
  const mesh = useRef<THREE.Mesh>(null!);
  const [count, setCount] = useState(0);
  const countPosition: [number, number, number] = [
    props.position[0],
    props.position[1] + 0.2,
    props.position[2],
  ];
  return (
    <>
      <Text color='white' position={countPosition} fontSize={0.3}>
        {count}
      </Text>
      <Interactive onSelect={() => setCount((c) => c + 1)} >
        <mesh
          ref={mesh}
          position={props.position}>
          <sphereGeometry args={[0.1]} />
          <meshStandardMaterial color={'blue'} />
        </mesh>
      </Interactive>
    </>
  )
}

export default Node;
