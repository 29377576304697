import {VRCanvas, DefaultXRControllers} from '@react-three/xr';
import {Link} from 'react-router-dom';
import AbstractGraphGame from '../components/abstract_graph_game';

export default function Graph() {
  return (
    <>
      <Link to="/">Home</Link>
      <Link to="/calculator">Calculator</Link>
      <VRCanvas>
        <DefaultXRControllers />
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <AbstractGraphGame />
      </VRCanvas>
    </>
  );
}
